import * as React from 'react';
import { MarkdownRemark } from '../../graphql-types';
import { PageSingle } from '../components/layouts/PageSingle/PageSingle';
import { Markdown } from '../components/Markdown/Markdown';
import { PageArticle } from '../components/layouts/PageArticle/PageArticle';

interface Props {
    readonly pageContext: {
        article: MarkdownRemark;
    };
}

export default class BaseSingleTemplate extends React.Component<Props> {
    render() {
        const { article } = this.props.pageContext;

        return (
            <PageArticle title={article.frontmatter.title}>
                <h2>{article.frontmatter.description}</h2>
                {/* <div>
                    {article.frontmatter.tags.map((tag: string, i: number) => {
                        return <span key={i}>{tag}</span>;
                    })}
                </div> */}
                {article.html && <Markdown html={article.html} />}
                {/* <pre>{JSON.stringify(this.props.pageContext, null, 4)}</pre> */}
            </PageArticle>
        );
    }
}
