import * as React from 'react';

import * as hljs from 'highlight.js';
import '../../../node_modules/highlight.js/styles/atelier-savanna-light.css';
import './Markdown.hljs.scss';

interface Props {
    html: string;
}

export class Markdown extends React.Component<Props> {

    componentDidMount() {
        setTimeout(() => {
            document.querySelectorAll('pre code').forEach((block: HTMLElement) => {
                hljs.highlightBlock(block);
            });
        }, 0);
    }
    render() {
        

        return <div dangerouslySetInnerHTML={{ __html: this.props.html }} />;
    }
}
