import * as React from 'react';

import { PageSingle, PageSingleProps } from '../PageSingle/PageSingle';
import styles from './PageArticle.module.scss';

interface Props extends PageSingleProps {}
export interface PageArticleProps extends PageSingleProps {}

export class PageArticle extends React.Component<Props> {
    render() {
        const { title, children } = this.props;

        return (
            <PageSingle title={title}>
                <article className={styles.article}>{children}</article>
            </PageSingle>
        );
    }
}
